import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  where
} from 'firebase/firestore';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './Dashboard.css';
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json';
import CircularScore from './CircularScore'; // Import the CircularScore component

function Dashboard() {
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  // For delete confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  // For post modal
  const [showPostModal, setShowPostModal] = useState(false);
  const [aboutPostText, setAboutPostText] = useState('');
  const [currentPostId, setCurrentPostId] = useState(null);

  // ----------
  //  FETCH LOGIC
  // ----------
  const fetchResponses = useCallback(async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const q = query(
          collection(db, `history/${user.uid}/responses`),
          orderBy('dateResponse', 'desc'),
          where('saveResponse', '==', true) // Ensure saveResponse is boolean and true
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log('No documents found for the current user with saveResponse=true.');
        } else {
          console.log('Fetched Documents:', querySnapshot.docs);
        }

        const fetchedResponses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          parsedTextResponse: processTextResponse(doc.data().textResponse)
        }));

        setResponses(fetchedResponses);
      } catch (error) {
        console.error('Error fetching responses:', error);
      }
    } else {
      console.error('No user is logged in.');
    }
    setIsLoading(false);
  }, []);

  console.log('Current User:', auth.currentUser);


  // ----------
  //  DELETE LOGIC (MODAL)
  // ----------
  function DeleteConfirmationModal({ isOpen, onDelete, onCancel }) {
    if (!isOpen) return null;
    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <h2>Are you sure you want to delete this post?</h2>
          <div className="modalButtons">
            <button onClick={onDelete}>Yes</button>
            <button onClick={onCancel}>No</button>
          </div>
        </div>
      </div>
    );
  }

  const promptDelete = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (selectedId) {
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, selectedId);
        try {
          await updateDoc(responseRef, { saveResponse: false });
          console.log(`Save response for ${selectedId} updated to false`);
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
      setShowDeleteModal(false);
      setSelectedId(null);
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };

  // ----------
  //  POST LOGIC (MODAL)
  // ----------
  function PostModal({ isOpen, onPost, onCancel }) {
    const [localAboutPostText, setLocalAboutPostText] = useState('');
  const [localFeaturesText, setLocalFeaturesText] = useState('');
  const [selectedPaymentModel, setSelectedPaymentModel] = useState('');
  
  // Single variable to store the chosen file
  const [iconFile, setIconFile] = useState(null);
  const [uploadError, setUploadError] = useState('');

    // Character constraints
    const MIN_ABOUT = 10;
    const MAX_ABOUT = 50;
    const MIN_FEATURES = 50;
    const MAX_FEATURES = 500;

    // Check length for "about" text
    const aboutLength = localAboutPostText.length;
    const aboutOverMin = aboutLength >= MIN_ABOUT;
    const aboutUnderMax = aboutLength <= MAX_ABOUT;
    const aboutRemaining = MAX_ABOUT - aboutLength;

    // Check length for "features" text
    const featuresLength = localFeaturesText.length;
    const featuresOverMin = featuresLength >= MIN_FEATURES;
    const featuresUnderMax = featuresLength <= MAX_FEATURES;
    const featuresRemaining = MAX_FEATURES - featuresLength;
    const [selectedFile, setSelectedFile] = useState(null);
    // PaymentModel must be selected
    const paymentModelSelected = !!selectedPaymentModel;

    // isValid if all constraints are satisfied
    const isValid =
      aboutOverMin &&
      aboutUnderMax &&
      featuresOverMin &&
      featuresUnderMax &&
      paymentModelSelected;

    // Reset state when modal closes
    useEffect(() => {
      if (!isOpen) {
        setLocalAboutPostText('');
        setLocalFeaturesText('');
        setSelectedPaymentModel('');
        setIconFile(null);
        setUploadError('');
      }
    }, [isOpen]);

     // When user picks a file
  const handleFileChange = (e) => {
    setUploadError('');
    const file = e.target.files?.[0];
    if (!file) {
      setIconFile(null);
      return;
    }

    // Validate file type
    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/jpg'
    ) {
      setUploadError('File must be a PNG or JPEG image.');
      setIconFile(null);
      return;
    }

    // Validate file size (<= 1 MB)
    const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
    if (file.size > MAX_FILE_SIZE) {
      setUploadError('File is too large. Must be <= 1 MB.');
      setIconFile(null);
      return;
    }

    // If valid
    setIconFile(file);
  };

  const handlePost = async () => {
    if (!isValid) return;

    try {
      let iconPostUrl = '';
      // If user selected a file, upload it
      if (iconFile) {
        const storage = getStorage();
        const user = auth.currentUser;
        const fileRef = storageRef(
          storage,
          `icons/${user.uid}/responseIcons/${Date.now()}-${iconFile.name}`
        );
        const uploadTask = uploadBytesResumable(fileRef, iconFile);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            () => {},
            reject,
            () => resolve()
          );
        });

        iconPostUrl = await getDownloadURL(fileRef);
      }

      // Pass everything back to the parent
      await onPost({
        aboutPost: localAboutPostText.trim(),
        featuresPost: localFeaturesText.trim(),
        paymentModel: selectedPaymentModel,
        iconPostUrl,
      });
    } catch (err) {
      console.error('Error uploading icon:', err);
      setUploadError('Failed to upload icon. Please try again.');
    }
  };

    if (!isOpen) return null;

    return (
      <div className="modalBackground">
        <div className="modalContainer">
          <h2>Write about your product:</h2>

          {/* About the product TextField */}
          <TextField
            label="About the product"
            variant="outlined"
            multiline
            rows={2}
            sx={{
              width: '500px',
              marginBottom: '1rem',
              '& .MuiOutlinedInput-root': {
                alignItems: 'flex-start',
                // #ddd border styling if desired
                '& fieldset': { borderColor: '#ddd' },
                '&:hover fieldset': { borderColor: '#ddd' },
                '&.Mui-focused fieldset': { borderColor: '#ddd' },
              },
              '& label.MuiFormLabel-root': {
                color: '#000',
              },
              '& label.Mui-focused': {
                color: '#000',
              },
            }}
            inputProps={{
              style: {
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              },
              maxLength: MAX_ABOUT, // Hard limit if you want
            }}
            placeholder={`About the product (${MIN_ABOUT}-${MAX_ABOUT} characters)`}
            value={localAboutPostText}
            onChange={(e) => setLocalAboutPostText(e.target.value)}
            error={!aboutOverMin || !aboutUnderMax} // Mark red if out of range
            helperText={
              aboutLength < MIN_ABOUT
                ? `Minimum ${MIN_ABOUT} chars. Currently ${aboutLength}.`
                : aboutLength > MAX_ABOUT
                  ? `Maximum ${MAX_ABOUT} chars. Currently ${aboutLength}.`
                  : `Characters: ${aboutLength}/${MAX_ABOUT} (Remaining: ${aboutRemaining})`
            }
          />

          <h2>Product Features:</h2>

          {/* Features TextField */}
          <TextField
            label="Product Features"
            variant="outlined"
            multiline
            rows={5}
            sx={{
              width: '500px',
              marginBottom: '1rem',
              '& .MuiOutlinedInput-root': {
                alignItems: 'flex-start',
                '& fieldset': { borderColor: '#ddd' },
                '&:hover fieldset': { borderColor: '#ddd' },
                '&.Mui-focused fieldset': { borderColor: '#ddd' },
              },
              '& label.MuiFormLabel-root': { color: '#000' },
              '& label.Mui-focused': { color: '#000' },
            }}
            inputProps={{
              style: {
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              },
              maxLength: MAX_FEATURES,
            }}
            placeholder={`Features (${MIN_FEATURES}-${MAX_FEATURES} characters)`}
            value={localFeaturesText}
            onChange={(e) => setLocalFeaturesText(e.target.value)}
            error={!featuresOverMin || !featuresUnderMax}
            helperText={
              featuresLength < MIN_FEATURES
                ? `Minimum ${MIN_FEATURES} chars. Currently ${featuresLength}.`
                : featuresLength > MAX_FEATURES
                  ? `Maximum ${MAX_FEATURES} chars. Currently ${featuresLength}.`
                  : `Characters: ${featuresLength}/${MAX_FEATURES} (Remaining: ${featuresRemaining})`
            }
          />

          <h2>Select Payment Model:</h2>
          <FormControl
            variant="outlined"
            sx={{
              m: 1,
              minWidth: 500,
              // Outline color
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd !important',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd !important',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#ddd !important',
              },
              // Force label color to black
              '& .MuiInputLabel-root': {
                color: 'black !important',
              },
              // Also for focused label
              '& .MuiInputLabel-root.Mui-focused': {
                color: 'black !important',
              },
            }}
          >
            <InputLabel id="PaymentModelLabel">Payment Model</InputLabel>
            <Select
              labelId="PaymentModelLabel"
              label="Payment Model"
              id="PaymentModel"
              value={selectedPaymentModel}
              onChange={(e) => setSelectedPaymentModel(e.target.value)}
            >
              <MenuItem value="Freemium">Freemium</MenuItem>
              <MenuItem value="Lifetime Access">Lifetime Access</MenuItem>
              <MenuItem value="Subscription">Subscription</MenuItem>
            </Select>
          </FormControl>



          {!selectedPaymentModel && (
            <p style={{ marginTop: '-10px', textAlign: "end", width: '500px', fontSize: "0.8rem" }}>Required</p>
          )}
          {/* <select
            style={{ marginBottom: '1rem' }}
            value={selectedPaymentModel}
            onChange={(e) => setSelectedPaymentModel(e.target.value)}
          >
            <option value="" disabled>
              Select a payment model
            </option>
            <option value="Freemium">Freemium</option>
            <option value="Lifetime Access">Lifetime Access</option>
            <option value="Subscription">Subscription</option>
          </select>
          {!selectedPaymentModel && (
            <p style={{ color: 'red', marginTop: '-10px' }}>Required</p>
          )} */}

<h2>Upload Icon (optional):</h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            component="label"
            variant="contained"
            // e.g. startIcon={<CloudUploadIcon />}
            sx={{
              width: '220px',
              backgroundColor: '#FF8C00',
            }}
          >
            Upload file
            {/* Hidden file input */}
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>

          {/* If a file is chosen, preview the details */}
          {iconFile && (
            <Typography variant="body2" color="text.secondary">
              <strong>File:</strong> {iconFile.name} | <strong>Format:</strong>{' '}
              {iconFile.type} |{' '}
              <strong>Size:</strong> {`${(iconFile.size / 1024).toFixed(1)} KB`}
            </Typography>
          )}
          {uploadError && (
            <Typography variant="body2" color="error">
              {uploadError}
            </Typography>
          )}
        </div>

          <div className="modalButtons">
            <button onClick={handlePost} disabled={!isValid}>
              Post
            </button>
            <button onClick={onCancel}>Cancel</button>
          </div>
          {!isValid && (
            <p style={{ color: '#000', fontSize: "1rem", width: "60%" }}>
              "About Product" must be {MIN_ABOUT}-{MAX_ABOUT} chars, "Features" {MIN_FEATURES}-
              {MAX_FEATURES} chars, and a Payment Model must be selected.
            </p>
          )}
        </div>
      </div >
    );
  }
  

  // ----------
  //  LIFECYCLE
  // ----------
  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // -----------
  //  TEXT PARSER
  // -----------
  const criteriaList = [
    'Design Foundations',
    'Visual Hierarchy',
    'Layout and Spacing',
    'Color',
    'Typography',
    'Theory',
    'Depth',
    'Design Assets',
    'Details'
  ];

  const processTextResponse = (textResponse) => {
    if (!textResponse) return {};

    // Split lines robustly
    const responseLines = textResponse.split(/\r?\n/);

    const sections = {};
    let currentSectionTitle = null;
    let currentText = [];
    let currentScore = null;

    responseLines.forEach((rawLine) => {
      let line = rawLine.trim();

      // Remove leading ### if present
      if (line.startsWith('###')) {
        line = line.replace(/^###\s*/, '');
      }

      // If line is "Score: 65", capture the score but don't discard the line
      const separateScore = line.match(/^Score:\s*(\d+(?:\/\d+)?)/i);
      if (separateScore) {
        currentScore = separateScore[1];
        // remove that text from the line
        line = line.replace(separateScore[0], '').trim();
      }

      // Check if line starts with a recognized heading
      // Also skip summary if you don’t want it
      let foundTitle =
        criteriaList.find((title) => line.toLowerCase().startsWith(title.toLowerCase())) ||
        (line.toLowerCase().startsWith('overall feedback') ? 'Overall Feedback' : null) ||
        (line.toLowerCase().startsWith('summary') ? 'Summary' : null);

      if (foundTitle) {
        // If we were in a prior section, finalize it
        if (currentSectionTitle && currentSectionTitle !== 'Summary') {
          sections[currentSectionTitle] = {
            text: currentText.join('\n'),
            score: currentScore
          };
        }

        // Skip summary headings
        if (foundTitle === 'Summary') {
          currentSectionTitle = null;
          currentText = [];
          currentScore = null;
          return;
        }

        currentSectionTitle = foundTitle;
        currentText = [];
        currentScore = null;

        // remove heading from the line text
        line = line.slice(foundTitle.length).trim();

        // If there's an inline "Score: XX" (e.g. "Depth Score: 60"), handle that
        const inlineScore = line.match(/score:\s*(\d+(?:\/\d+)?)/i);
        if (inlineScore) {
          currentScore = inlineScore[1];
          line = line.replace(inlineScore[0], '').trim();
        }
      } else {
        // Maybe line is "Score: 60" plus text. We can detect that too, if needed
        const inlineScore = line.match(/score:\s*(\d+(?:\/\d+)?)/i);
        if (inlineScore) {
          currentScore = inlineScore[1];
          line = line.replace(inlineScore[0], '').trim();
        }
      }

      // Add leftover text to the current section
      if (currentSectionTitle && line) {
        currentText.push(line);
      }
    });

    // finalize last section
    if (currentSectionTitle && currentSectionTitle !== 'Summary') {
      sections[currentSectionTitle] = {
        text: currentText.join('\n'),
        score: currentScore
      };
    }

    return sections;
  };
  // -----------
  //  DELETE HELPER
  // -----------
  const deleteResponse = async (id) => {
    const user = auth.currentUser;
    if (user) {
      const responseRef = doc(db, `history/${user.uid}/responses`, id);
      try {
        await updateDoc(responseRef, {
          saveResponse: false
        });
        console.log(`Save response for ${id} updated to false`);
        fetchResponses();
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    }
  };

  // -----------
  //  POST HELPER
  // -----------
  const togglePost = async (id, currentPostStatus) => {
    if (currentPostStatus) {
      // If already posted, toggling it "unposts" it
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, id);
        try {
          await updateDoc(responseRef, {
            postResponse: !currentPostStatus
          });
          console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
    } else {
      // If not posted yet, open the "PostModal"
      setCurrentPostId(id);
      setShowPostModal(true);
    }
  };

  // Called when user confirms "Post"
  const confirmPost = async ({ aboutPost, featuresPost, paymentModel, iconPostUrl }) => {
    if (currentPostId && aboutPost) {
      const user = auth.currentUser;
      if (user) {
        const responseRef = doc(db, `history/${user.uid}/responses`, currentPostId);
        try {
          await updateDoc(responseRef, {
            postResponse: false,
            aboutPost,
            featuresPost,
            paymentModel,
            postReview: true, // Mark the post for review
            iconPostUrl: iconPostUrl || '' // store icon URL in Firestore
          });
          console.log(
            `Post updated with aboutPost: ${aboutPost}, featuresPost: ${featuresPost}, paymentModel: ${paymentModel}, iconPostUrl: ${iconPostUrl}, postReview: true`
          );
          fetchResponses();
        } catch (error) {
          console.error('Error updating document: ', error);
        }
      }
      setShowPostModal(false);
      setCurrentPostId(null);
    }
  };

  const cancelPost = () => {
    setShowPostModal(false);
    setAboutPostText('');
    setCurrentPostId(null);
  };

  // ----------
  //  RENDER
  // ----------
  return (
    <div className="dashboard">
      {isLoading ? (
        <div className="loader">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      ) : responses.length === 0 ? (
        <div className="emptyState">
          <h2>You haven't any saved posts</h2>
        </div>
      ) : (
        responses.map((response) => (
          <div key={response.id} className="responseCard">
            <div className="titleInformationBox">
              <div className="date">Author: {response.email}</div>
              <div className="date">{response.siteUrl}</div>
              <div className="datetime">
                {response.dateResponse?.toDate().toLocaleString()}
              </div>
            </div>
            <div className="divider"></div>
            <div className="responseCardSections">
              <div className="responseBoxBoard">
                <div className="responseImageBox">
                  <img
                    src={response.imageUrl}
                    alt="Response Screenshot"
                    className="responseImage"
                  />
                </div>
              </div>

              <div className="responseBoxBoardRight">
                {Object.entries(response.parsedTextResponse).map(
                  ([key, { text, score }], index) => (
                    <div key={index} className="responseBox">
                      <div className="overallScoreBox">
                        <h3>{key}</h3>
                        {score && (
                          <span className="scoreStyle">
                            <CircularScore score={score} />
                          </span>
                        )}
                      </div>
                      <p
                        style={{
                          whiteSpace: 'pre-wrap',
                          fontSize: '1rem',
                          textAlign: 'justify'
                        }}
                      >
                        {text}
                      </p>
                    </div>
                  )
                )}
              </div>

              {/* Delete Confirmation Modal */}
              <DeleteConfirmationModal
                isOpen={showDeleteModal}
                onDelete={confirmDelete}
                onCancel={cancelDelete}
              />

              {/* Post Modal (for "Post" or "Unpost") */}
              <PostModal
                isOpen={showPostModal}
                onPost={confirmPost}
                onCancel={cancelPost}
              />
            </div>

            {/* Action buttons */}
            <div className="actionPostButtons">
              <div
                className="actionButton"
                onClick={() => togglePost(response.id, response.postResponse)}
              >
                {response.postResponse ? 'Unpost' : 'Post'}
              </div>
              <div className="actionButton" onClick={() => promptDelete(response.id)}>
                Delete
              </div>
            </div>

            {/* Show like count if posted */}
            {response.postResponse && (
              <div className="likes">Likes: {response.likeResponse || 0}</div>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default Dashboard;
