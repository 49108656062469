import React from 'react';
import './Plans.css';
import { FaCheckCircle, FaStar } from 'react-icons/fa';

const Plans = () => {
  return (
    <div className="pricing-page">
      <h1>Free Access</h1>
      <div className="desciptionAccessArea">
        <p className="desciptionAccess">
          Score Me is currently free to use, allowing you to post one score daily.
          Our goal is to build a strong community first. Enjoy the tools we provide and join
          us in shaping the future of product scoring!
        </p>
      </div>
      <div>If you want to support us or upgrade your account:</div>
      <div className="desciptionAccessArea">
        <p>Product Hunt</p>
        <p>IndieHacker</p>
        <p>Donate</p>
      </div>

      <div className="pricing-cards">
        {/* Monthly Subscription Card */}
        <PriceCard
          icon={<FaStar />}
          label="Most popular"
          title="Premium"
          description="Premium plan. Stay tuned with us and build community (monthly subscription)."
          price="7.99"
          period="/month"
          details={['Unlimited usage', '24/7 support', 'Verify post within 24 hours']}
          onClick={() => {
            // Replace with your Gumroad "Monthly" subscription link
            window.location.href = 'https://yevhenms.gumroad.com/l/bcnutg';
          }}
        />

        {/* Yearly Subscription Card */}
        <PriceCard
          icon={<FaStar />}
          label="Best price"
          title="Premium"
          description="Premium plan. Stay tuned with us and build community (annual subscription)."
          price="67"
          period="/year (Save 30%)"
          details={['Unlimited usage', '24/7 support', 'Verify post within 24 hours']}
          onClick={() => {
            // Replace with your Gumroad "Yearly" subscription link
            window.location.href = 'https://yevhenms.gumroad.com/l/uxezut';
          }}
        />
      </div>
    </div>
  );
};

const PriceCard = ({ icon, label, title, description, price, period, details, onClick }) => {
  return (
    <div className="price-card">
      <div className="priceCardTop">
        <div>
          <div className="priceCardIcon">{icon}</div>
        </div>
        {label && (
          <div>
            <div className="priceCardLabel">{label}</div>
          </div>
        )}
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="pricePeriod">
        <h3>${price}</h3>
        <span>{period}</span>
      </div>
      <div className="divider"></div>
      <ul>
        {details.map((detail, index) => (
          <li key={index}>
            <FaCheckCircle />
            {detail}
          </li>
        ))}
      </ul>
      <div className="divider"></div>
      <button className="priceCardButton" onClick={onClick}>
        Get Started
      </button>
    </div>
  );
};

export default Plans;
