import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import {
  collectionGroup,
  query,
  getDocs,
  where,
  doc,
  getDoc,
  updateDoc,
  orderBy
} from 'firebase/firestore';
import './AdminDashboard.css';

function AdminDashboard() {
  const [posts, setPosts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [topWidget, setTopWidget] = useState(false);

  // 1) Check if current user is an admin
  const checkAdminStatus = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.admin) {
            setIsAdmin(true);
          }
        } else {
          console.error('No such user document!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
    setLoading(false);
  }, []);

  // 2) Fetch topWidget setting
  const fetchTopWidgetSetting = useCallback(async () => {
    try {
      const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj');
      const docSnap = await getDoc(controlPanelRef);
      if (docSnap.exists()) {
        setTopWidget(docSnap.data().topWidget || false);
      } else {
        console.log('No such document in controlPanel!');
      }
    } catch (error) {
      console.error('Error fetching topWidget setting:', error);
    }
  }, []);

  // 3) Toggle topWidget
  const handleToggleTopWidget = async () => {
    try {
      const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj');
      await updateDoc(controlPanelRef, { topWidget: !topWidget });
      setTopWidget(!topWidget);
    } catch (error) {
      console.error('Error updating topWidget setting:', error);
    }
  };

  /**
   * 4) Fetch all posts from all users where "postReview" = true
   *    We do this via collectionGroup('responses').
   */
  const fetchPostsForReview = useCallback(async () => {
    setLoading(true);
    try {
      // Add orderBy("dateResponse", "desc") after where()
      const q = query(
        collectionGroup(db, 'responses'),
        where('postReview', '==', true),
        orderBy('dateResponse', 'desc')
      );
  
      const snapshot = await getDocs(q);
      console.log('Number of docs found:', snapshot.size);
  
      let fetchedPosts = [];
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        // docSnap.ref.parent.parent => the user doc
        const ownerId = docSnap.ref.parent.parent.id; // get the userId from path
  
        fetchedPosts.push({
          id: docSnap.id,
          ownerId: ownerId,
          ...data
        });
      });
  
      setPosts(fetchedPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
    setLoading(false);
  }, []);

  /**
   * 5) Toggle postResponse between true/false for a post
   */
  const togglePostStatus = async (ownerId, postId, currentStatus) => {
    try {
      const postRef = doc(db, `history/${ownerId}/responses`, postId);
      await updateDoc(postRef, {
        postResponse: !currentStatus
      });
      console.log(`Post ${postId} for user ${ownerId} => postResponse=${!currentStatus}`);
      // Refresh the list
      fetchPostsForReview();
    } catch (error) {
      console.error('Error updating post status:', error);
    }
  };

  // 6) On mount, check admin & fetch topWidget
  useEffect(() => {
    checkAdminStatus();
    fetchTopWidgetSetting();
  }, [checkAdminStatus, fetchTopWidgetSetting]);

  // 7) If admin, fetch posts needing review
  useEffect(() => {
    if (isAdmin) {
      fetchPostsForReview();
    }
  }, [isAdmin, fetchPostsForReview]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>You are not authorized to view this page.</div>;
  }

  return (
    <div className="adminDashboard">
      <h1>Admin Dashboard</h1>
      <div className="toggle-switch">
        Top Widget: 
        <label className="switch">
          <input
            type="checkbox"
            checked={topWidget}
            onChange={handleToggleTopWidget}
          />
          <span className="slider round"></span>
        </label>
        <span className="toggle-label">{topWidget ? 'On' : 'Off'}</span>
      </div>

      {posts.length === 0 ? (
        <p>No posts to review.</p>
      ) : (
        posts.map((post) => (
          <div key={post.id} className="postCardAdmins">
            <div>
              <h2>Author: {post.email}</h2>
              <p><strong>Site URL:</strong> {post.siteUrl}</p>
              {post.dateResponse && (
                <p>
                  <strong>Post Date:</strong> {post.dateResponse.toDate().toLocaleString()}
                </p>
              )}
              <p><strong>About the Post:</strong> {post.aboutPost}</p>
              <p><strong>Features:</strong> {post.featuresPost}</p>
              <p><strong>Payment Model:</strong> {post.paymentModel}</p>

              <p><strong>Post Status:</strong> {post.postResponse ? 'Posted' : 'Not Posted'}</p>

              {post.postResponse ? (
                <button
                  onClick={() =>
                    togglePostStatus(post.ownerId, post.id, post.postResponse)
                  }
                  className="unpostButton"
                >
                  Unpost
                </button>
              ) : (
                <button
                  onClick={() =>
                    togglePostStatus(post.ownerId, post.id, post.postResponse)
                  }
                  className="postButton"
                >
                  Post
                </button>
              )}
            </div>
            <div>
              <img
                src={post.imageUrl}
                alt="ScoreMe.io - score your product or design."
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default AdminDashboard;
