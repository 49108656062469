import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Menu from "./Menu";
import Dashboard from "./Dashboard";
import Main from "./Main";
import Feed from "./Feed";
import Footer from "./Footer";
import Landing from "./Landing";
import SignUp from "./SignUp";
import Plans from "./Plans";
import { ThemeProvider } from "./ThemeContext";
import { PrivateRoute, PublicRoute } from "./PrivateRoute";
import AdminDashboard from "./AdminDashboard";
import Launch from "./Launch";
import HallOfFame from "./HallOfFame";
import { db } from "./firebase"; // Import Firestore instance
import { doc, getDoc } from "firebase/firestore";
import TermsAndConditions from "./TermsAndCondition";
import PrivacyPolicy from "./PrivacyPolicy";
import SignIn from "./SignIn";
import RestorePassword from "./RestorePassword";
import Landingtest from "./Landing-test";
import PaymentSuccess from "./PaymentSuccess";

function App() {
  const [isPreLaunch, setIsPreLaunch] = useState(null);

  useEffect(() => {
    const fetchPreLaunchStatus = async () => {
      try {
        const controlPanelRef = doc(db, "controlPanel", "8SGFUGkF19XbA9LgkVfj");
        const docSnap = await getDoc(controlPanelRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsPreLaunch(data.Prelaunch || false);
        } else {
          console.log("No such document!");
          setIsPreLaunch(false);
        }
      } catch (error) {
        console.error("Error fetching pre-launch status:", error);
        setIsPreLaunch(false);
      }
    };

    fetchPreLaunchStatus();
  }, []);

  if (isPreLaunch === null) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Redirect to /launch if pre-launch is true */}
            {isPreLaunch && (
              <>
                <Route path="/launch" element={<Launch />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="*" element={<Navigate to="/launch" replace />} />
              </>
            )}

            {/* Standard routes if not in pre-launch mode */}
            {!isPreLaunch && (
              <>
                {/* Public Routes */}
                <Route element={<PublicRoute restricted={false} />}>
                  <Route path="/" element={<Landing />} />
                  <Route path="/test" element={<Landingtest />} />
                </Route>
                <Route element={<PublicRoute restricted={true} />}>
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="/restore-password" element={<RestorePassword />} />
                </Route>

                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<AppWithHeaderAndFooter><Main /></AppWithHeaderAndFooter>} />
                  <Route path="/dashboard" element={<AppWithHeaderAndFooter><Dashboard /></AppWithHeaderAndFooter>} />
                  <Route path="/feed" element={<AppWithHeaderAndFooter><Feed /></AppWithHeaderAndFooter>} />
                  <Route path="/products" element={<AppWithHeaderAndFooter><HallOfFame /></AppWithHeaderAndFooter>} />
                  <Route path="/admin" element={<AppWithHeaderAndFooter><AdminDashboard /></AppWithHeaderAndFooter>} />
                  <Route path="/plans" element={<AppWithHeaderAndFooter><Plans /></AppWithHeaderAndFooter>} />
                  <Route path="/payment-success" element={<AppWithHeaderAndFooter><PaymentSuccess/></AppWithHeaderAndFooter>} />

                </Route>

                {/* Always Accessible Routes */}
                <Route path="/terms-and-conditions" element={<AppWithHeaderAndFooter><TermsAndConditions /></AppWithHeaderAndFooter>} />
                <Route path="/privacy" element={<AppWithHeaderAndFooter><PrivacyPolicy /></AppWithHeaderAndFooter>} />

                {/* Redirect Launch Route */}
                <Route path="/launch" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

// Component to wrap routes that need Header and Footer
const AppWithHeaderAndFooter = ({ children }) => (
  <div className="App">
    <header className="App-header">
      <Menu />
    </header>
    <main className="App-main">
      {children}
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default App;
