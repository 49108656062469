import React, { useEffect, useRef, useState } from 'react';
import './LandingTest.css';
import Logo from './images/Logo.png';
import Sample from './images/man-characters.webp';
import step1 from './images/step1.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';
import step4 from './images/step4.png';
import './ParallaxSpace.css'; // Create a separate CSS file for styles
import { useNavigate } from 'react-router-dom';

function Launch() {
  const [doggyTranslate, setDoggyTranslate] = useState(100); // State for controlling doggy position
  const [showBanner, setShowBanner] = useState(true); // New state for notification banner visibility
  const elementsRef = useRef([]);
  const animationFrameIdRef = useRef(null);
  const navigate = useNavigate();

  // Refs for scrolling to sections
  const aboutRef = useRef(null);
  const howItWorksRef = useRef(null);

  useEffect(() => {
    // Apply the animation to each element
    elementsRef.current.forEach((el) => {
      if (el) {
        el.classList.add('animated');
      }
    });
  }, []);

  const canvasRef = useRef(null);
  const stars = [];

  const createStars = (numStars) => {
    for (let i = 0; i < numStars; i++) {
      stars.push({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight,
        radius: Math.random() * 1.5 + 0.5,
        speed: Math.random() * 0.5 + 0.2,
      });
    }
  };

  const drawStars = (ctx) => {
    ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
    stars.forEach((star) => {
      ctx.beginPath();
      ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
      ctx.fillStyle = 'white';
      ctx.fill();
      // Move stars downward with speed
      star.y += star.speed;
      if (star.y > window.innerHeight) {
        star.y = 0;
        star.x = Math.random() * window.innerWidth;
      }
    });
  };

  const animate = () => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    drawStars(ctx);
    animationFrameIdRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    elementsRef.current.forEach((el) => {
      if (el) {
        el.classList.add('animated');
      }
    });
  }, []);

  useEffect(() => {
    createStars(100);
    animationFrameIdRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }
    };
  }, []);

  return (
    <div className='landMainTest'>
      {/* Notification Banner with Close Button */}
      {showBanner && (
        <div className='notifBanner'>
          <span 
            className='closeBanner' 
            onClick={() => setShowBanner(false)}
            style={{ cursor: 'pointer', float: 'right', marginRight: '1rem' }}
          >
            X
          </span>
          👋 Welcome to ScoreMe.io! Our launch on{' '}
          <a href="https://www.producthunt.com/posts/scoreme-io-product-scoring-sharing">
            Product Hunt
          </a>{' '}
          is planned for March 4th, 2025, at 12:01 AM PST.
        </div>
      )}
      <div
        className='landHeaderTest animate-fade-drop animate-delay-1'
        ref={(el) => (elementsRef.current[0] = el)}
      >
        <div className='logoArea'>
          <img src={Logo} alt='logo' className='logoImg' />
          <span className='logoText'>scoreme.io</span>
        </div>
        <div className='landButtons'>
          {/* Menu buttons now scroll smoothly to sections */}
          <a
            href="#"
            className='menuButton'
            onClick={(e) => {
              e.preventDefault();
              aboutRef.current &&
                aboutRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            About
          </a>
          <a
            href="#"
            className='menuButton'
            onClick={(e) => {
              e.preventDefault();
              howItWorksRef.current &&
                howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });
            }}
          >
            How it works
          </a>
          <span
            onClick={() => navigate('/signup')}
            className='buttonSignUp'
            onMouseEnter={() => setDoggyTranslate(-40)}
            onMouseLeave={() => setDoggyTranslate(100)}
          >
            Get started free
          </span>
          <span onClick={() => navigate('/signin')} className='buttonSignIn'>
            Sign in
          </span>
        </div>
      </div>

      <div className="parallax-background-test">
        <canvas
          ref={canvasRef}
          width={window.innerWidth}
          height={window.innerHeight}
        />
        <div className='boardBoxB'>
          <div className='boardBoxC'>
            <div
              className='boardBoxTest animate-fade-drop animate-delay-2'
              ref={(el) => (elementsRef.current[1] = el)}
            >
              <div className='leftBoardBoxTest'>
                <h1 className='productTitleTest'>
                  Revolutionizing Product Insights with AI-Driven Scoring & Analysis
                </h1>
                <div className='descProductTextTest'>
                  Through Advanced Scoring Dashboards, Users Can Analyze, Compare,
                  and Share Products, Making Data-Driven Decisions Without Hassle
                </div>
                <div className='boxCenterButtonTest'>
                  <div
                    className='waitlistButtonTest'
                    onClick={() => navigate('/signup')}
                    onMouseEnter={() => setDoggyTranslate(-40)}
                    onMouseLeave={() => setDoggyTranslate(100)}
                  >
                    Start now
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About Section */}
      <div className='boardBoxATest'>
        <div ref={aboutRef}>
          <h3>CONNECT, SCORE, SHARE AND BUILD YOUR COMMUNITY</h3>
          <h1 style={{ marginBlockEnd: '0px' }}>
            Exceptional Product Insights with Custom Scoring, Shareability, and Transparency
          </h1>
        </div>
        <div className='middleBoxTest'>
          <div className='middleBoxDiv'>
            <div>
              <p>
                With ScoreMe.io, users can evaluate products, access actionable insights,
                and share scores publicly without complex spreadsheets or guesswork.
              </p>
            </div>
            <img src={Sample} alt="Sample" />
          </div>
          <div className='middleBoxDivTwo'>
            <div className='boxInDiv'>
              <div>
                <h2>Estimated Product More Value</h2>
                <p>
                  With AI-powered assistance, public sharing you can evaluate your product and unlock opportunity to growth.
                </p>
              </div>
              <div className='boxInInput'>
                <input type="text" placeholder="Write URL of your product" />
                <button onClick={() => navigate('/signup')}>Score me</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How it works Section */}
      <div className='middleBox'>
        <div style={{ textAlign: "center" }} ref={howItWorksRef}>
          <h1 style={{ marginBlockEnd: '0px' }}>How it works</h1>
          <p style={{ marginBlockStart: '0px', fontSize: "1rem", textAlign: "center" }}>
            Start your journey, score your product and build community around your creativity.
          </p>
        </div>
        <div className="customCardsContainer">
          <div className="customCard">
            <div className="customCardContent">
              <h3 style={{ color: 'white' }}>Sign up</h3>
              <p>
                Create an account with scoreme.io - provide Email, UserName or fast method via Google or Twitter (X).
              </p>
            </div>
            <div className="customCardImageWrapper">
              <img src={step1} alt="Sign up" className="customCardImage" />
            </div>
          </div>
          <div className="customCard">
            <div className="customCardImageWrapper">
              <img src={step2} alt="Score Product" className="customCardImage" />
            </div>
            <div className="customCardContent">
              <h3 style={{ color: 'white' }}>Score Product</h3>
              <p>
                Provide your Product Digiral URL. Choose screen and conditions that you want to be scored.
              </p>
            </div>
          </div>
          <div className="customCard">
            <div className="customCardContent">
              <h3 style={{ color: 'white' }}>Share your Product</h3>
              <p>
                Post your product in Feed. Fill the form about your product, product features, payment model and icon.
              </p>
            </div>
            <div className="customCardImageWrapper">
              <img src={step3} alt="Share Product" className="customCardImage" />
            </div>
          </div>
          <div className="customCard">
            <div className="customCardImageWrapper">
              <img src={step4} alt="Build Your Community" className="customCardImage" />
            </div>
            <div className="customCardContent">
              <h3 style={{ color: 'white' }}>Build Your Community</h3>
              <p>
                Get detailed control over characters, settings, and actions with exceptional alignment of videos with textual prompts.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='footerLand'>
        <div className='footerRows'>
          <div className='footerColumns'>
            <div className='footerRow'>
              <div className='logoFooter'>
                <img src={Logo} alt='logo' className='logoImg' />
                <span className='logoText'>scoreme.io</span>
              </div>
              <div>
                ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
              </div>
            </div>
            <div className='footerRow'>
              <div className='menuButtonItem'>
                <b>Score Me</b>
              </div>
              <div className='menuButtonItem'>
                How it works
              </div>
              <div className='menuButtonItem'>
                Score digital products
              </div>
              <div className='menuButtonItem' onClick={() => navigate('/terms-and-conditions')}>
                Terms & Conditions
              </div>
              <div className='menuButtonItem' onClick={() => navigate('/privacy')}>
                Privacy Policy
              </div>
              <div className='menuButtonItem'>
                Feed
              </div>
            </div>
            <div className='footerRow'>
              <div>
                <b>Social Networks</b>
              </div>
              <div className='menuButtonItem'>
                <a href='https://x.com/ScoreMeIo'>X</a>
              </div>
              <div className='menuButtonItem'>
                Instagram
              </div>
              <div className='menuButtonItem'>
                Facebook
              </div>
              <div className='menuButtonItem'>
                Email
              </div>
            </div>
          </div>
        </div>
        <div>
          © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
        </div>
      </div>
    </div>
  );
}

export default Launch;
