import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { auth, db, applyLatestOrder } from './firebase'; 
import { doc, collection, setDoc, serverTimestamp } from 'firebase/firestore';

/**
 * Displayed after Gumroad redirects here on successful payment.
 * Example URL:
 *   https://scoreme.io/payment-success?period=month&subscriptionId=1
 */
const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
  const user = auth.currentUser; // The currently logged-in user
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;

    // Read the payment period from the URL. Defaults to 'month'
    const paymentPeriod = searchParams.get('period') || 'month';
    
    // Use a session storage flag keyed by user and payment period.
    // This key remains constant for all users (since subscriptionId is now fixed).
    const orderFlagKey = `orderProcessed_${user.uid}_${paymentPeriod}`;

    if (sessionStorage.getItem(orderFlagKey)) {
      console.log('Order already processed. Skipping duplicate creation.');
      return;
    }

    const createOrder = async () => {
      try {
        const ordersRef = collection(db, 'orders');
        const newOrderRef = doc(ordersRef);
        await setDoc(newOrderRef, {
          userId: user.uid,
          subscriptionId: searchParams.get('subscriptionId') || '', // will be fixed (e.g. "1")
          paymentPeriod: paymentPeriod,
          timestamp: serverTimestamp(),
        });

        // Set the flag in sessionStorage so duplicate orders won't be created on refresh
        sessionStorage.setItem(orderFlagKey, 'true');

        // After saving the order, update the user's status.
        await applyLatestOrder(user.uid);

        console.log('Payment success: order created and applied.');

        // Optionally redirect the user to another page (e.g. dashboard)
        navigate('/home');
      } catch (err) {
        console.error('Error creating/updating order:', err);
      }
    };

    createOrder();
  }, [user, searchParams, navigate]);

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <h1>Payment Successful!</h1>
      <p>Thank you for subscribing. Your account will be updated shortly.</p>
    </div>
  );
};

export default PaymentSuccess;
