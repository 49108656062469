import React, { useEffect, useState } from 'react';
import './Menu.css';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from './firebase';
import Logo from './images/Logo.png';
import avatarImage from './images/placeholder-icon.png';
import { FaBars, FaCrown, FaSun, FaMoon } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { useTheme } from './ThemeContext';

function Menu() {
  const [user, setUser] = useState(null);
  const [daysWithUs, setDaysWithUs] = useState(0);
  const [currentPlan, setCurrentPlan] = useState('Free');
  const [planLimit, setPlanLimit] = useState(2);
  const [dailyRefreshTime, setDailyRefreshTime] = useState('');
  const [rateAmount, setRateAmount] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState(avatarImage);

  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const isNight = theme === 'night';

  // Listen for auth changes and load user data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();

          // Calculate days with us
          const regDate = userData.regDate?.toDate?.() || new Date();
          const today = new Date();
          const days = Math.floor((today - regDate) / (1000 * 60 * 60 * 24));
          setDaysWithUs(days);

          // Plan & rate
          const status = userData.status || 'Free';
          setCurrentPlan(status.charAt(0).toUpperCase() + status.slice(1));

          const limits = { free: 1, premium: 20, elite: 50 };
          setPlanLimit(limits[status.toLowerCase()] || 2);
          setRateAmount(userData.rateAmount || 0);
          setExpiredDate(userData.expiredDate || null);

          // Set avatar from userData or fallback
          const userImage = userData.avatarUserUrl || avatarImage;
          setAvatarUrl(userImage);
        } else {
          setRateAmount(0);
          setExpiredDate(null);
          setAvatarUrl(avatarImage);
        }
      } else {
        setAvatarUrl(avatarImage);
      }

      setIsLoadingUser(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  // Calculate time remaining until the next GMT+0 midnight
  const calculateTimeToNextMidnightGMT = () => {
    const now = new Date();
    const gmtNow = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    // Next midnight in GMT+0
    const nextMidnight = new Date(
      gmtNow.getUTCFullYear(),
      gmtNow.getUTCMonth(),
      gmtNow.getUTCDate() + 1,
      0, 0, 0
    );

    const diff = nextMidnight - gmtNow;

    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  // Update `dailyRefreshTime` every second
  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = calculateTimeToNextMidnightGMT();
      setDailyRefreshTime(remainingTime);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Helper function to get expiry display text
  const getExpiryDisplay = () => {
    // If no expiredDate, return "N/A"
    if (!expiredDate) {
      return 'N/A';
    }

    // Convert expiredDate (Firestore Timestamp or string) to Date object
    const expiry =
      expiredDate.toDate instanceof Function ? expiredDate.toDate() : new Date(expiredDate);
    const now = new Date();
    // Calculate the difference in days (rounded up)
    const diffTime = expiry - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays > 0) {
      return `${diffDays} Day${diffDays === 1 ? '' : 's'}`;
    } else {
      return `Expired ${Math.abs(diffDays)} Day${Math.abs(diffDays) === 1 ? '' : 's'} ago`;
    }
  };

  return (
    <div className='menu'>
      <div className='logoArea'>
        <img src={Logo} alt='logo' className='logoImg' />
        <span className='logoText'>scoreme.io</span>

        <div className="theme-toggle">
          <input
            type="checkbox"
            id="theme-toggle-checkbox"
            className="theme-toggle-checkbox"
            checked={isNight}
            onChange={toggleTheme}
          />
          <label className="theme-toggle-label" htmlFor="theme-toggle-checkbox">
            <FaSun className="icon sun-icon" />
            <FaMoon className="icon moon-icon" />
            <div className="ball"></div>
          </label>
        </div>
      </div>

      <div className='menuItem'>
        <div className='menuButtonItem' onClick={() => navigate('/home')}>Rate</div>
        <div className='menuButtonItem' onClick={() => navigate('/feed')}>Feed</div>
        <div className='menuButtonItem' onClick={() => navigate('/dashboard')}>Dashboard</div>
      </div>

      <div className='menuLogin'>
        {user ? (
          <>
            <div className='displayMenuDiv'>
              <div className='amountCredentials'>
                Credentials: {!isLoadingUser ? (Number(rateAmount) > 10 ? '∞' : rateAmount) : 'Loading...'}
              </div>
              <div className='buyCredits' onClick={() => navigate('/plans')}>Upgrade</div>

              {/* Show the avatar */}
              <div className='photoProfile'>
                <img
                  src={avatarUrl}
                  referrerPolicy="no-referrer"
                  alt="Profile"
                  style={{ width: '30px', height: '30px', borderRadius: '50%' }}
                />
              </div>

              <div className='menuIcon' onClick={toggleMenu}>
                {isMenuOpen ? (
                  <i className='icon-cross'><FaXmark /></i>
                ) : (
                  <i className='icon-hamburger'><FaBars /></i>
                )}
              </div>

              {isMenuOpen && (
                <div className='modalMenu'>
                  <div className='menuBlock'>
                    Welcome, {user.displayName || user.email}!
                  </div>
                  <div className='menuBlock'>
                    Days with us: <span>{daysWithUs}</span>
                  </div>
                  <div className='menuBlock'>
                    Current Plan: <span>{currentPlan}</span>
                  </div>
                  {/* Only display expired info if current plan is not free */}
                  {currentPlan.toLowerCase() !== 'free' && (
                    <div className='menuBlock'>
                      Expired: <span>{getExpiryDisplay()}</span>
                    </div>
                  )}
                  <div className='menuBlock'>
                    Credentials: <span>{Number(rateAmount) > 10 ? '∞' : rateAmount}</span>
                  </div>
                  <div className='menuBlock'>
                    Daily Refresh: <span>{dailyRefreshTime}</span>
                  </div>
                  <div className='menuBlock'>
                    <div className='menuUpgradeButton'>
                      Upgrade <FaCrown />
                    </div>
                  </div>
                  <div className='menuBlock'>
                    <div className='authMenuButton'>
                      <span onClick={handleSignOut}>Sign Out</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <button>Login with Google</button>
            <button>Login with Twitter</button>
          </>
        )}
      </div>
    </div>
  );
}

export default Menu;
