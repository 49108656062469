import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  serverTimestamp,
  getDoc,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDrwOK1UBebXKXh_PBqoe_wMEyRJnXW0z8",
  authDomain: "rateme-f61b9.firebaseapp.com",
  projectId: "rateme-f61b9",
  storageBucket: "rateme-f61b9.appspot.com",
  messagingSenderId: "641326334074",
  appId: "1:641326334074:web:bd42837fbbc926494ebbc3",
  measurementId: "G-2Z84TB0DCQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Auth
export const auth = getAuth(app);
export const googleAuthProvider = new GoogleAuthProvider();
export const twitterAuthProvider = new TwitterAuthProvider();
export const db = getFirestore(app);

/**
 * Calculate expiration date based on the payment period.
 * @param {string} paymentPeriod - "annual" or "month".
 * @returns {Date} A JavaScript Date object.
 */
// For example, if you pass 'month' or 'annual' from PaymentSuccess:
const calculateExpiryDate = (paymentPeriod) => {
  const now = new Date();
  if (paymentPeriod === "annual") {
    now.setUTCDate(now.getUTCDate() + 365);
  } else if (paymentPeriod === "month") {
    now.setUTCDate(now.getUTCDate() + 30);
  }
  return now;
};


/**
 * Check and apply the latest valid order for the user.
 * @param {string} userId - The user's UID.
 */
export const applyLatestOrder = async (userId) => {
  try {
    const ordersRef = collection(db, "orders");
    const userOrdersQuery = query(
      ordersRef,
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      limit(1) // Fetch only the most recent order
    );

    const querySnapshot = await getDocs(userOrdersQuery);

    if (querySnapshot.empty) {
      console.log("No orders found for this user.");
      return;
    }

    const latestOrder = querySnapshot.docs[0].data();
    const { paymentPeriod, timestamp } = latestOrder;

    if (!paymentPeriod || !["annual", "month"].includes(paymentPeriod)) {
      console.error("Invalid paymentPeriod in the order:", latestOrder);
      return;
    }

    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const { status, expiredDate } = userDoc.data();
      const now = new Date();

      // Only update if the latest order is newer than the current expiredDate
      if (!expiredDate || expiredDate.toDate() < timestamp.toDate()) {
        const newExpiryDate = calculateExpiryDate(paymentPeriod);

        await updateDoc(userRef, {
          status: "Premium",
          rateAmount: 10000,
          expiredDate: newExpiryDate,
        });

        console.log(
          `User upgraded to Premium. Expiry set to: ${newExpiryDate.toISOString()}`
        );
      } else {
        console.log(
          "Latest order is not newer than current expiration date. No update applied."
        );
      }
    } else {
      console.error("User document not found.");
    }
  } catch (error) {
    console.error("Error applying latest order:", error);
  }
};

export const decrementRateAmount = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await checkAndSwitchToFree(userRef);

    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const { rateAmount } = userDoc.data();
      if (rateAmount > 0) {
        await updateDoc(userRef, { rateAmount: rateAmount - 1 });
        console.log("rateAmount decremented by 1.");
      } else {
        console.log("rateAmount is already 0.");
      }
    } else {
      console.error("User document not found.");
    }
  } catch (error) {
    console.error("Error decrementing rateAmount:", error);
  }
};

/**
 * Create or update a user in Firestore.
 * @param {Object} user - The Firebase user object.
 * @param {Object} [additionalData={}] - Additional user data to store.
 */
export const createOrUpdateUser = async (user, additionalData = {}) => {
  if (!user) return;

  const userRef = doc(db, "users", user.uid);
  const docSnap = await getDoc(userRef);

  if (docSnap.exists()) {
    const userData = docSnap.data();

    // Ensure Premium users are checked for expiry
    await checkAndSwitchToFree(userRef);

    const lastUpdated = userData.lastUpdated?.toDate() || new Date(0);
    const today = new Date();
    const isDifferentDay =
      today.toISOString().slice(0, 10) !== lastUpdated.toISOString().slice(0, 10);

    if (userData.status === "Free" && isDifferentDay && userData.rateAmount < 1) {
      await updateDoc(userRef, {
        rateAmount: 1,
        lastUpdated: new Date(), // Update lastUpdated field
      });
      console.log("rateAmount updated to 1 for free user.");
    }
  } else {
    const defaultData = {
      email: user.email || "",
      userName: user.displayName || "",
      rateAmount: 1,
      regDate: serverTimestamp(),
      status: "Free",
      avatarUserUrl: user.photoURL || "/placeholder-icon.png",
      lastUpdated: serverTimestamp(),
    };

    const userData = { ...defaultData, ...additionalData };
    await setDoc(userRef, userData);
    console.log("User created:", userData);
  }
};

/**
 * Switch user status to Free if Premium has expired.
 * @param {Object} userRef - Firestore reference to the user document.
 */
const checkAndSwitchToFree = async (userRef) => {
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const { status, expiredDate } = userDoc.data();
    const now = new Date();

    if (status === "Premium" && expiredDate && expiredDate.toDate() < now) {
      // Switch to Free if expired
      await updateDoc(userRef, {
        status: "Free",
        rateAmount: 1, // Reset to Free default
        expiredDate: null, // Remove expiredDate
      });
      console.log("Premium expired. User switched to Free status.");
    }
  }
};

export default app;

