import React, { useEffect, useRef, useState } from 'react';
import './LandingTest.css';
import Logo from './images/Logo.png';
import Arrow from './images/Arrow.png';
import Sample from './images/man-characters.webp';
import Lottie from 'react-lottie';
import animationData from './images/mainAnim2.json';
import step1 from './images/step1.png';
import step2 from './images/step2.png';
import step3 from './images/step3.png';
import step4 from './images/step4.png';
import animationDataDog from './images/doggy.json';
import ScreenHome from './images/ScreenHome.jpg';
import CardImage from './images/robot.webp';
import CardImage2 from './images/dashboard.webp';
import ParallaxSpace from './ParallaxSpace'; // Import the ParallaxSpace component
import './ParallaxSpace.css'; // Create a separate CSS file for styles
import { useNavigate } from 'react-router-dom';
import CircularScore from './CircularScore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

function Landingtest() {
    const [doggyTranslate, setDoggyTranslate] = useState(100); // State for controlling doggy position
    const elementsRef = useRef([]);
    // We'll store the animation frame ID here so we can cancel it
    const animationFrameIdRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Apply the animation to each element
        elementsRef.current.forEach((el, index) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    const canvasRef = useRef(null);
    const stars = [];


    const createStars = (numStars) => {
        for (let i = 0; i < numStars; i++) {
            stars.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                radius: Math.random() * 1.5 + 0.5,
                speed: Math.random() * 0.5 + 0.2,
            });
        }
    };

    const drawStars = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        stars.forEach((star) => {
            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fillStyle = 'white'; // Star color
            ctx.fill();
            // Move stars downward with speed
            star.y += star.speed;
            if (star.y > window.innerHeight) {
                star.y = 0;
                star.x = Math.random() * window.innerWidth;
            }
        });
    };

    // 3) Animation loop
    const animate = () => {
        // Double-check canvas is still mounted
        if (!canvasRef.current) {
            return; // If unmounted, just stop
        }
        const ctx = canvasRef.current.getContext('2d');
        if (!ctx) {
            return; // If we have no context, stop
        }

        drawStars(ctx);
        animationFrameIdRef.current = requestAnimationFrame(animate);
    };

    // 4) On component mount
    useEffect(() => {
        // Animate your elements
        elementsRef.current.forEach((el) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    // 5) Star creation + animation start
    useEffect(() => {
        createStars(100); // Adjust the number of stars as needed
        animationFrameIdRef.current = requestAnimationFrame(animate);

        // Cleanup: cancel the animation frame
        return () => {
            if (animationFrameIdRef.current) {
                cancelAnimationFrame(animationFrameIdRef.current);
            }
        };
    }, []);
    return (
        <div className='landMainTest'>
            <div className='landHeaderTest animate-fade-drop animate-delay-1' ref={(el) => elementsRef.current[0] = el}>
                <div className='logoArea'>
                    <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                </div>
                <div className='landButtons'>
                    <a href='' className='menuButton'>About</a>
                    <a href='' className='menuButton'>How it works</a>
                    <span onClick={() => navigate('/signup')} className='buttonSignUp'
                        onMouseEnter={() => setDoggyTranslate(-40)} // Move doggy on hover
                        onMouseLeave={() => setDoggyTranslate(100)}>Get started free</span>
                    <span onClick={() => navigate('/signin')} className='buttonSignIn'>Sign in</span>
                </div>
            </div>
            <div className="parallax-background-test">
                <canvas
                    ref={canvasRef}
                    width={window.innerWidth}
                    height={window.innerHeight}

                />
                <div className='boardBoxB '>
                    <div className='boardBoxC'>
                        <div className='boardBoxTest animate-fade-drop animate-delay-2' ref={(el) => elementsRef.current[1] = el}>
                            <div className='leftBoardBoxTest'>
                                <h1 className='productTitleTest'>Revolutionizing Product Insights with AI-Driven Scoring & Analysis</h1>
                                <div className='descProductTextTest'>
                                    Through Advanced Scoring Dashboards, Users Can Analyze, Compare, and Share Products, Making Data-Driven Decisions Without Hassle
                                </div>
                                <div className='boxCenterButtonTest'>
                                    <div className='waitlistButtonTest' onClick={() => navigate('/singup')} onMouseEnter={() => setDoggyTranslate(-40)} // Move doggy on hover
                                        onMouseLeave={() => setDoggyTranslate(100)}>Start now</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='boardBoxATest'>
                <div>
                    <h3>CONNECT, SCORE, SHARE AND BUILD YOUR COMMUNITY</h3>
                    <h1>Exceptional Product Insights with Custom Scoring, Shareability, and Transparency</h1>
                </div>
                <div className='middleBoxTest'>
                    <div className='middleBoxDiv'>
                        <div style={{ fontSize: "0.8rem", fontWeight: "500" }}>100+ products estimated</div>
                        <div><p>With ScoreMe.io, users can evaluate products, access actionable insights, and share scores publicly without complex spreadsheets or guesswork.</p></div>
                        <img src={Sample} />
                    </div>
                    <div className='middleBoxDivTwo'>
                        <div className='boxInDiv'>
                            <div>
                                <h2>Estimated Product More Value</h2>
                                <p>With AI-powered assistance, public sharing you can evaluate your product and unlock opportunity to growth.</p>
                            </div>
                            <div className='boxInInput'>
                                <input
                                    type="text"
                                    placeholder="Write URL of your product"
                                /><button>Score me</button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='middleBox'>
                    <div style={{ textAlign: "center", marginBottom: "100px" }}>
                        <h1 style={{ marginBlockEnd: '0px' }}>How it works</h1>
                        <p style={{ marginBlockStart: '0px', fontSize: "1rem", textAlign: "center" }}>Start your journey, score your product and build community around your creativity.</p>
                    </div>
                    <div>
                        <div className='stepDiv'>

                            <div className='stepDivImage'><div className="stepImageBox"> <img src={step1} /> </div></div>
                            <div className='stepDivLine'>
                            <Timeline position="alternate" style={{display: "flex", alignItems: "center"}}>
                            <TimelineItem>
                                <TimelineSeparator>
                                
                                    <TimelineDot />
                                    <TimelineConnector style={{paddingTop: "300px"}}/>
                                </TimelineSeparator>
                            </TimelineItem>   
                            </Timeline>
                            </div>
                            <div className='stepDivText'>
                                <div style={{margin: '60px'}}>
                                <h2>Sign up</h2>
                                <p style={{ fontSize: "1rem" }}>Create an account with scoreme.io - provide Email, UserName or fast method with SSO.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='stepDiv'>
                            <div className='stepDivText'>
                            <div style={{margin: '60px'}}>
                                <h2>Score Product</h2>
                                <p style={{ fontSize: "1rem" }}>Provide your Product Digiral URL. Choose screen and conditions that you want to be scored.</p>
                                </div>
                            </div>
                            <div className='stepDivLine'>
                            <Timeline position="alternate" style={{display: "flex", alignItems: "center"}}>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector style={{paddingTop: "300px"}}/>
                                </TimelineSeparator>
                            </TimelineItem>   
                            </Timeline>
                            </div>
                            <div className='stepDivImage'> <img src={step2} /> </div>
                        </div>
                        <div className='stepDiv'>
                            <div className='stepDivImage'> <img src={step3} /> </div>
                            <div className='stepDivLine'>
                            <Timeline position="alternate" style={{display: "flex", alignItems: "center"}}>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector style={{paddingTop: "300px"}}/>
                                </TimelineSeparator>
                            </TimelineItem>   
                            </Timeline>
                            </div>
                            <div className='stepDivText'>
                            <div style={{margin: '60px'}}>
                                <h2>Share your Product</h2>
                                <p style={{ fontSize: "1rem" }}>Post your product in Feed. Fill the form about your product, product features, payment model and icon.</p>
                            </div>
                            </div>
                        </div>
                        <div className='stepDiv'>
                            <div className='stepDivText'>
                            <div style={{margin: '60px'}}>
                                <h2>Build Your Community</h2>
                                <p style={{ fontSize: "1rem" }}>Upvote different products, speak with other creators in comments, promote your product for growth!</p>
                           </div>
                           </div>
                            <div className='stepDivLine'>
                            <Timeline position="alternate" style={{display: "flex", alignItems: "center"}}>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector style={{paddingTop: "300px"}}/>
                                </TimelineSeparator>
                            </TimelineItem>   
                            </Timeline>
                            </div>
                            <div className='stepDivImage'> <img src={step4} /> </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footerLand'>
                <div className='footerRows'>
                    <div className='footerColumns'>
                        <div className='footerRow'>
                            <div className='logoFooter'>
                                <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                            </div>
                            <div>
                                ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
                            </div>
                        </div>
                        <div className='footerRow'>
                            <div className='menuButtonItem'>
                                <b>Score Me</b>
                            </div>
                            <div className='menuButtonItem'>
                                How it works
                            </div>
                            <div className='menuButtonItem'>
                                Score digital products
                            </div>
                            <div className='menuButtonItem' onClick={() => navigate('/terms-and-conditions')}>
                                Terms & Conditions
                            </div>
                            <div className='menuButtonItem' onClick={() => navigate('/privacy')}>
                                Privacy Policy
                            </div>
                            <div className='menuButtonItem'>
                                Feed
                            </div>
                        </div>
                        <div className='footerRow'>
                            <div>
                                <b>Social Networks</b>
                            </div>
                            <div className='menuButtonItem'>
                                <a href='https://x.com/ScoreMeIo'>X</a>
                            </div>
                            <div className='menuButtonItem'>
                                Instagram
                            </div>
                            <div className='menuButtonItem'>
                                Facebook
                            </div>
                            <div className='menuButtonItem'>
                                Email
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
                </div>
            </div>
        </div>
    );
}

export default Landingtest;
